.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.el_form .form-inline {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.el-image {
  width: 100%;
}
.el-image img {
  display: block;
  margin: 0 auto;
}
